// Card
@mixin homeCarMixin($bgColor, $boxShadow, $boxShadow2) {
    cursor: pointer;
    background-color: $bgColor;
    padding: 10px 10px;
    box-shadow: $boxShadow !important;
    border-radius: 5px !important;
    transition: .3s ease-in-out;
    margin: 0px 10px;
    height:60px;
    &:hover {
        // margin-top: -10px;
        transform: scale(1.1);
        box-shadow: $boxShadow2 !important;
    }
}


@mixin homeCarMixin2($bgColor, $boxShadow, $boxShadow2) {
    cursor: pointer;
    background-color: $bgColor;
    padding: 10px;
    box-shadow: $boxShadow !important;
    border-radius: 5px !important;
    transition: .3s ease-in-out;
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    &:hover {
        // margin-top: -10px;
        transform: scale(1.1);
        box-shadow: $boxShadow2 !important;
    }
}

@mixin homeCarMixin3($bgColor, $boxShadow, $boxShadow2) {
    cursor: pointer;
    background-color: $bgColor;
    padding: 10px 10px;
    box-shadow: $boxShadow !important;
    border-radius: 5px !important;
    transition: .3s ease-in-out;
    margin: 0px 10px;
    &:hover {
        // margin-top: -10px;
        // transform: scale(1.1);
        box-shadow: $boxShadow2 !important;
    }
}